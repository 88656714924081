import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { ordersData, ordersGrid } from "../../data/dummy";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import {
  fetchAnnouncementsList,
  fetchApplicationsList,
  fetchExpiredTokensList,
  fetchExpiringTokensList,
} from "../../utils/HttpUtils";
import { useStatContext } from "../../contexts/ContextProvider";
import { TiTicket } from "react-icons/ti";
import { BiTrash, BiUser } from "react-icons/bi";
import Modal from "../../components/Modal";
import { SiLinux } from "react-icons/si";

const TokenExpires = () => {
  const { setCurrentToast } = useStatContext();
  const [tokens, setTokens] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [modalIcon, setModalIcon] = useState(<BiTrash />);

  const [currentExpireMode, setCurrentExpireMode] = useState(
    localStorage.getItem("current_expire_mode")
  );

  const modes = {};
  modes["expiring"] = { name: "Expiring", route: "expires" };
  modes["expired"] = { name: "Expired", route: "expired" };

  const fetchExpiringTokens = async () => {
    try {
      const resp = await fetchExpiringTokensList();
      setTokens(resp);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-600",
      });
    }
  };

  const fetchExpiredTokens = async () => {
    try {
      const resp = await fetchExpiredTokensList();
      setTokens(resp);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-600",
      });
    }
  };

  useEffect(() => {
    if (currentExpireMode === "expiring") {
      fetchExpiringTokens();
    } else {
      fetchExpiredTokens();
    }
  }, []);

  return (
    <div
      className="relative m-10 p-5
    dark:bg-secondary-dark-bg font-network"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category="Token" title="Token Expires" />
      <div
        className="inline-flex flex-wrap
        m-2 mt-4"
      >
        {Object.keys(modes).map((mode) => {
          return (
            <button
              key={mode}
              type="button"
              onClick={() => {
                localStorage.setItem("current_expire_mode", mode);
                setCurrentExpireMode(mode);
                if (mode === "expiring") {
                  fetchExpiringTokens();
                } else {
                  fetchExpiredTokens();
                }
              }}
              className={`inline-block ${
                mode === currentExpireMode
                  ? "bg-[#1ffea9] text-black"
                  : "bg-black text-[#1ffea9]"
              } px-6
              border-1 border-[#1ffea9]
              pb-2 pt-2.5 text-xs 
              font-medium uppercase 
              leading-normal 
              transition duration-150 ease-in-out 
              hover:bg-[#1ffea9]
              focus:outline-none
              focus:ring-0
               hover:text-black`}
            >
              {modes[mode].name}
            </button>
          );
        })}
      </div>
      {tokens && tokens.length !== 0 && (
        <div className="flex justify-start mt-5">
          <table className="border-1 border-[#1ffea9] font-body">
            <thead className="text-[#1ffea9] text-sm border-b-1 border-[#1ffea9]">
              <tr>
                <th className="pl-4 py-1 text-center">#</th>
                <th className="pl-4 py-1 text-center">Token</th>
                <th className="pl-4 py-1 text-center">Start</th>
                <th className="pl-4 py-1 text-center">Expire</th>
                <th className="pl-4 py-1 text-center">Days</th>
                <th className="pl-4 py-1 text-center">Devices</th>
                <th className="pl-4 py-1 text-center">OS IDs</th>
                <th className="pl-4 pr-4 py-1 text-center">User IDs</th>
              </tr>
            </thead>
            <tbody className="text-[#1ffea9] text-sm">
              {tokens.map((token, index) => {
                let newTokenStr = "";
                if (
                  token &&
                  token.token.length > 1 &&
                  token.token.length !== 19
                ) {
                  for (var i = 0; i < token.token.length; i++) {
                    if (i != 0 && i % 4 == 0) {
                      newTokenStr += "-";
                    }
                    newTokenStr += token.token.charAt(i);
                  }
                } else {
                  newTokenStr = token.token;
                }

                let startDate = new Date(token.start);
                var startMM = startDate.getMonth() + 1; // getMonth() is zero-based
                var startDD = startDate.getDate();

                let startFullDate = [
                  startDate.getFullYear(),
                  (startMM > 9 ? "" : "0") + startMM,
                  (startDD > 9 ? "" : "0") + startDD,
                ].join("-");
                token.start = startFullDate;

                let expireDate = new Date(token.expire);
                var expireMM = expireDate.getMonth() + 1; // getMonth() is zero-based
                var expireDD = expireDate.getDate();

                let expireFullDate = [
                  expireDate.getFullYear(),
                  (expireMM > 9 ? "" : "0") + expireMM,
                  (expireDD > 9 ? "" : "0") + expireDD,
                ].join("-");
                token.expire = expireFullDate;
                return (
                  <tr key={token.id} className="border-[#1ffea9] ">
                    <td className="pl-4 py-1 text-nowrap">{index + 1}</td>
                    <td className="pl-4 py-1 text-nowrap">{newTokenStr}</td>
                    <td className="pl-4 py-1 text-nowrap">{token.start}</td>
                    <td className="pl-4 py-1 text-nowrap">{token.expire}</td>
                    <td className="pl-4 py-1 text-center">{token.days}</td>
                    <td className="pl-4 py-1 text-center">
                      {token.device_num}
                    </td>
                    <td className="pl-4 py-1">
                      <button
                        onClick={() => {
                          setModalIcon(
                            <SiLinux className="mx-auto text-[#1ffea9] text-3xl" />
                          );
                          setModalDesc(token.os_id);
                          setModalOpen(true);
                        }}
                        className="border-1 border-[#1ffea9] p-1 hover:bg-[#1ffea9] hover:text-black"
                      >
                        Show
                      </button>
                    </td>
                    <td className="pl-4 pr-4 py-1">
                      <button
                        onClick={() => {
                          setModalIcon(
                            <BiUser className="mx-auto text-[#1ffea9] text-3xl" />
                          );
                          setModalDesc(token.user_id);
                          setModalOpen(true);
                        }}
                        className="border-1 border-[#1ffea9] p-1 hover:bg-[#1ffea9] hover:text-black"
                      >
                        Show
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="text-center">
          {modalIcon}
          <div className="mx-auto my-4">
            <h3 className="text-lg font-black text-[#1ffea9]">ID</h3>
            <p className="text-sm text-white">{modalDesc}</p>
          </div>
          <div className="flex gap-4">
            <button
              className="bg-[#1ffea9] py-1 text-black w-full"
              onClick={async () => {
                // try {
                //   const result = await questionableAction();
                //   fetchAnnouncements();
                //   setCurrentToast({
                //     isToasted: true,
                //     text: result,
                //     color: "[#1ffea9]",
                //   });
                // } catch (error) {
                //   setCurrentToast({
                //     isToasted: true,
                //     text: error.message,
                //     color: "red-500",
                //   });
                // }

                setModalOpen(false);
              }}
            >
              Ok
            </button>
            <button
              className="bg-red-500 py-1 text-black btn btn-light w-full"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TokenExpires;
